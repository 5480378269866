<template>
  <div class="news-detail-page mobile">
    <div class="page-content">

      <div class="p-products">
        <!-- 面包屑导航 -->
        <el-breadcrumb>
          <el-breadcrumb-item>
            <router-link to="/">{{$t('layout.homePage')}}</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('layout.news')}}</el-breadcrumb-item>
        </el-breadcrumb>

        <div>
          <p style="text-align: center;font-size: 20px;font-weight: bold">{{$i18n.locale === 'zh-CN' ? news.zhTitle : news.enTitle}}</p>
          <p style="text-align: center;font-size: 16px;">{{news.gmtCreated}}</p>
          <div v-if="$i18n.locale === 'zh-CN'" v-html="news.zhContent" style="word-break: break-all;"></div>
          <div v-else v-html="news.enContent" style="word-break: break-all;"></div>
        </div>

        <div>
          <p v-if="news.previousNews">{{$t('news.previousNews')}}：
            <router-link :key="`/news/news-detail?newsId=${news.previousNews.newsId}`" :to="`/news/news-detail?newsId=${news.previousNews.newsId}`">
              {{$i18n.locale === 'zh-CN' ? news.previousNews.zhTitle : news.previousNews.enTitle}}
            </router-link>
          </p>
          <p v-if="news.nextNews">{{$t('news.nextNews')}}：
            <router-link :key="`/news/news-detail?newsId=${news.nextNews.newsId}`" :to="`/news/news-detail?newsId=${news.nextNews.newsId}`">
              {{$i18n.locale === 'zh-CN' ? news.nextNews.zhTitle : news.nextNews.enTitle}}
            </router-link>
          </p>
        </div>

      </div>
    </div>
  </div>
</template>


<script>

  export default {

    data() {
      return {
        menus: [],
        news: {}
      };
    },

    created() {
      window.page = this;
      this.menus = [{
        id: this.$t('layout.companyNews'),
        label: this.$t('layout.companyNews'),
      }, {
        id: this.$t('layout.industryNews'),
        label: this.$t('layout.industryNews'),
      }];
      this.query(this.$route.query.newsId);
    },

    watch: {
      $route(newValue, oldValue) {
        console.log("new:"+newValue);
        console.log("old:"+oldValue);
        if (newValue.query.newsId !== this.newsId) {
          this.query(newValue.query.newsId);
        }
      }
    },

    methods: {
      query(id) {
        const loading = this.$loading();
        this.$ajax.get('/news/' + id).then(res => {
          if (res.code !== 100) {
            return;
          }

          this.news = res.data;
        }).finally(() => loading.close());
      },

      changeType(a, b, c) {
        this.$router.push({path: '/news/news-list',query: {type: a.label}});
      }
    }

  }
</script>


<style lang="scss">
  .news-detail-page.mobile {
    background-color: var(--white);

    img {
      max-width: 100%;
    }

    .page-content {
      width: 100%;
      max-width: 1128px;
      margin: 0 auto;

      display: flex;
      justify-content: center;

      padding-bottom: 50px;
    }

    .p-products {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      overflow-x: hidden;

      flex: 1;
      max-width: 800px;
      margin-left: 15px;
      margin-right: 15px;

      .el-breadcrumb {
        height: 48px;
        margin-left: 15px;
        line-height: 60px;
      }

      .product-table {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 15px;

        .product {
          width: 100%;
          margin-top: 15px;
          position: relative;
          cursor: pointer;

          .name {
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            text-align: center;
            color: var(--black);
            background-color: rgb(242, 242, 242);

            position: absolute;
            bottom: 15px;
            left: 20px;
            right: 20px;
          }

          .news-content {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 20px;
            height: 40px;
            word-break: break-all;
          }
        }
      }

      .el-pagination {
        margin-top: 50px;
        text-align: center;
      }
    }
  }
</style>
